import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';

ClassNameGenerator.configure((componentName) => componentName.replace('Mui', 'V5')); // load as early as possible this code in a js file

/* eslint-disable react/prop-types */
import productImageModule from '@next-common/product-image';
import { getServerSidePropsLoader, PagePropsType, regroupModuleReducers } from '@next-common/redux-apollo-helper';

import React from 'react';
import { renderReact } from '../../fragmentHelper/renderReactCustom';

import { ReduxApolloFragmentWrapperChildren } from '../../fragmentHelper/Wrapper';
import { GetServerSidePropsHypernovaWatchContext } from '../../types';
import '@next-common/product-image/build/style.global.css';
import { configService as configurationService } from '@next-common/config-service/dist/configService';
import Box from '@mui/material/Box';

export { productImageModule };

const { ProductImageContainer } = productImageModule.containers;
declare global {
  interface Window {
    hypernovaProductImageLazyInit: any;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const reducers = regroupModuleReducers([productImageModule]);

const WrappedProductImage: React.FC<PagePropsType> = ({ initialApolloState, initialReduxState, moduleName }) => {
  return (
    <ReduxApolloFragmentWrapperChildren
      initialApolloState={initialApolloState}
      initialReduxState={initialReduxState}
      moduleName={moduleName}
      reducers={reducers}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
        }}
      >
        <ProductImageContainer />
      </Box>
    </ReduxApolloFragmentWrapperChildren>
  );
};

export const serverCanCache = true;

export const renderFunction = renderReact('ProductImage', WrappedProductImage);

if (typeof window !== 'undefined') {
  window.hypernovaProductImageLazyInit = (): void => {
    renderReact('ProductImage', WrappedProductImage);
  };
}
const modules = [productImageModule];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const getServerSideProps = async (watchContext: GetServerSidePropsHypernovaWatchContext) => {
  const baseUrl = null;
  const wsBaseUrl = configurationService.get().gatewayUrl;

  const preloadedConfig = {
    ProductImage: {
      productId: watchContext.jobData?.productId !== undefined ? watchContext.jobData.productId : null,
      wsBaseUrl,
      galleryIdBaseUrl: process.env.GALLERY_ID_URL,
      galleryDataGatewayUrl: process.env.GALLERY_DATA_URL,
    },
  };

  const graphQlVariables = {};
  const graphQlQuery = '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { initialReduxState, initialApolloState } = await getServerSidePropsLoader(
    watchContext,
    modules,
    baseUrl,
    preloadedConfig,
    graphQlQuery,
    graphQlVariables
  );

  const customStatusCode = initialReduxState?.productImage?.customStatusCode;

  return {
    props: {
      initialReduxState,
      initialApolloState,
      customStatusCode,
    },
  };
};
